<template>
    <div class="grid">
        <div class="col-12">
            <Loading v-if="loading" />
            <div class="card">
                <div>
                    <h5 class="titulo">Usuário</h5>
                    <div class="botoes_topo" v-if="this.modo == 'visualizar'">
                        <Button @click="modoEditar()" label="Editar" class="p-button-primary" />
                    </div>
                </div>

                <div class="grid">
                    <div class="col-1">
                        <label>Cód</label>
                        <InputText v-model="empresa_carregada.id" type="text" readonly="" />
                    </div>
                    <div class="col-5">
                        <label>Nome</label>
                        <InputText v-model="nome_usuario" type="text" />
                    </div>
                    <div class="col-6">
                        <label>CPFs</label>
                        <InputText  v-model="cpf_usuario" mask="999.999.999-99"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-4">
                        <label>Data Nascimento</label>
                        <InputText type="text" v-model="data_nascimento" />
                    </div>
                    <div class="col-4">
                        <label>E-mail</label>
                        <InputText v-model="email_usuario" />
                    </div>
                    <div class="col-4">
                        <label>Senha</label>
                        <InputText  v-model="senha_usuario" />
                    </div>
                </div>
                <h5 class="titulo_pedidos">Endereço</h5>
                <div class="grid">
                    <div class="col-9">
                        <label>Logradouro</label>
                        <InputText type="text" v-model="logradouro_usuario" />
                    </div>
                    <div class="col-3">
                        <label>Número</label>
                        <InputText  v-model="num_usuario" />
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label>Bairro</label>
                        <InputText type="text" v-model="bairro_usuario" autocomplete="off" name="new-password"/>
                    </div>
                    <div class="col-6">
                        <label>CEP</label>
                        <InputText type="text" v-model="cep_usuario" />
                    </div>
                </div>
                <div>
                    <div class="div_botoes">
                        <Button label="Salvar" class="p-button-primary btn_success" @click="salvarUsuario()" />
                        <Button label="Cancelar" class="p-button-primary btn_cancel" @click="modoVisualizar()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { reactive } from 'vue'
import InputMask from 'primevue/inputmask';
export default {
    setup() {
        const state = reactive({
            logo_url: '',
            logo_file: '',
            areas_atuacao: []
        })
        return {
            state,
                              
        }
    },
    data() {
        return {
            InputMask,
            representante_id: this.$storage.getStorageSync("user").representante_id,
            msg_fim_sessao: 'A sessão expirou. Faça Login para voltar ao Hub.',
            msg_erro: 'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_carregada: {
                nome_fantasia: '',
                razao_social: '',
                cnpj: '',
                email: '',
                //    areas_atuacao:[],
                logo_url: ''
            },
            modo: 'visualizar'
        }
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
        this.carregarAreaAtuacao()


        if (this.$router.currentRoute.value.params.id != undefined != undefined) {
            this.carregarEmpresa()
        }
    },
    methods: {
        alterarLogo(event) {
            const element = event.target.files[0];
            this.state.logo_url = URL.createObjectURL(element);
            this.state.logo_file = element
            console.log(this.state.logo_url)
        },
        carregarEmpresa() {
            this.loading = true;
            if (this.$router.currentRoute.value.params.id != undefined) {
                this.api.carregarEmpresa(this.token, this.$router.currentRoute.value.params.id).then(data => {
                    this.empresa_carregada = data.data.resposta
                    this.state.logo_url = data.data.logo
                    this.area_atuacao_id = data.data.resposta.area_atuacao_id
                    this.loading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            } else {
                this.modo = "editar"
            }
            this.loading = false;
        },
        salvarUsuario() {
            var usuario = {};
            usuario.nome_usuario = this.nome_usuario;
            usuario.cpf_usuario = this.cpf_usuario;
            usuario.data_nascimento = this.data_nascimento;
            usuario.email_usuario = this.email_usuario;
            usuario.senha_usuario = this.senha_usuario;
            usuario.logradouro_usuario = this.logradouro_usuario;
            usuario.num_usuario = this.num_usuario;
            usuario.bairro_usuario = this.bairro_usuario;
            usuario.cep_usuario = this.cep_usuario;
            if (this.$router.currentRoute.value.params.id != undefined) {
                this.api.atualizarEmpresa(this.token, this.$router.currentRoute.value.params.id).then(data => {
                    if (data.data.verifica_erro == false) {
                        this.$router.push('/');
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            } else {
                this.api.salvarUsuario(this.token, usuario).then(data => {
                    if (data.data.verifica_erro == false) {
                        this.$router.push('/');
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }


        },
        carregarAreaAtuacao() {
            this.api.listarAreasAtuacao().then(data => {
                this.state.areas_atuacao = data.data
                this.loading = false;
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        modoEditar() {
            this.modo = "editar"
        },
        modoVisualizar() {
            this.modo = "visualizar"
        }
    },
    components: { Loading }
}
</script>

<style>
label {
    font-size: 12pt;
    font-weight: bold;
}

.titulo {
    display: inline-block;
    width: 50%;
}

.botoes_topo {
    display: inline-block;
    width: 50%;
    text-align: right
}

input {
    margin: 0px;
    width: 100%
}

.div_botoes {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.btn_cancel {
    background-color: #9d9d9d;
    border: #9d9d9d solid 1px;
    margin-left: 10px;
    width: 15rem;
}

.btn_cancel:hover {
    background-color: #9d9d9d !important;
    border: #9d9d9d solid 1px !important;
}

.btn_success {
    width: 15rem;
}

.select_empresa {
    width: 100%;
    border: 1px solid #ced4da;
    padding: 0.75rem 0.75rem;
    background-color: #fff;
    border-radius: 6px;
    color: #495057;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.inputFile {
    width: 100%;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-bottom: #000 solid 1px;
}

.inputFile input {
    opacity: 0;
    filter: alpha(opacity=0);
    right: 0;
    padding-top: 5px;
    z-index: 2;
    height: 80px;
    font-size: 100px;
}

.imagem_logo {
    height: 80px;
}</style>